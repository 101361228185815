import React, { useState, useRef, useCallback } from "react";
import { Tabs, Tab, TabContainer } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import WhatsApp from "../../../images/icons/whatsapp-alt-0.png";
import Llamada from "../../../images/icons/phone-alt-0.png";
import Mail from "../../../images/icons/mail-alt-0.png";

export default function FormularioBasico({children, pagina, btnTitulo, props}){

    const [validationToken, setValidationToken] = useState("");
    const [selected, setSelected] = useState("WhatsApp");
    const [whatsapp, setWhatsApp] = useState("");
    const recaptchaRef = useRef();

    function handleKeyPress(event) {
        setWhatsApp(event.target.value);
    }

    //console.log("Seleccionado: ", props);

    const handleVerify = useCallback(
        (token) => {
        setValidationToken(token);
        },
        [setValidationToken]
    );

    function verifyToken(event) {
        if (validationToken === "" || validationToken === null) {
            console.log("ESTA VACIO EL CAPTCH");
            alert("Error en el captcha");
            return;
        }
        event.target.submit();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        verifyToken(event);
    };

    return(
        <>
                <input type="hidden" name='captcha_settings'
                    value={
                        JSON.stringify({
                        "keyname": "SecondCaptcha",
                        "fallback": "true",
                        "orgId": "00Df4000004ls8N",
                        "ts": new Date().getTime(),
                        })
                    }
                    />
                <input type="hidden"
                id="g-recaptcha-response"
                name="g-recaptcha-response"
                value={validationToken}></input>

                <form onSubmit={handleSubmit} action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">

                    <div className="form-group" >
                        <input type="hidden" id="orgid" name="orgid" value="00Df4000004ls8N"/>
                        <input type="hidden" id="priority" name="priority" value="Media"/>
                        <input type="hidden" value="Nuevo" name="status" />
                        <input type="hidden" id="recordType" name="recordType" value="0122S0000009FMD" />
                        <input type="hidden" name="retURL" value={`${props.location.protocol}//${props.location.host}/gracias-por-contactarnos/formulario`} />
                        <input
                            type="hidden"
                            id="00Nf400000UBhZ6"
                            name="00Nf400000UBhZ6"
                            value="gruporivero.com"
                        />
                    </div>

                    <div className="form-group pb-3">
                        <label htmlFor="name">Nombre</label>
                        <input
                            required
                            className="form-control"
                            id="first_name"
                            maxLength={50}
                            name="first_name"
                            size="20"
                            type="text"
                            placeholder="Nombre"
                        />
                    </div>

                    {children}

                    <div className="form-group pb-3">
                        <label htmlFor="contact-value">¿Cómo deseas que te contactemos?</label>

                        <Tabs id="controlled-tab-example" activeKey={selected} onSelect={(k) => setSelected(k)} className="nav-fill m-0" >
                            <Tab eventKey="WhatsApp" title={
                                <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("WhatsApp")}>
                                    <img src={WhatsApp} className="mr-1" style={{width: 20}} alt="WhatsApp chevrolet rivero linda vista"/>
                                    <span className={`text-primary ml-1 ${selected==='WhatsApp'? ``:`d-none`}`}>WhatsApp</span>
                                </div>
                            }>
                                <div className="rounded-bottom w-100 p-3" style={{border: "1px solid #dee2e6", borderTop: 0}}>
                                    <input
                                        id="contact-value"
                                        type="tel"
                                        className="form-control"
                                        placeholder="WhatsApp a 10 dígitos"
                                        onKeyUp={(e) => handleKeyPress(e)}
                                        pattern="[0-9]{10}"
                                        required={selected === "WhatsApp"}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="Llamada" title={
                                <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("Llamada")} >
                                    <img src={Llamada} className="mr-1" style={{width: 20}} alt="telefono chevrolet rivero linda vista"/>
                                    <span className={`text-primary ml-1 ${selected==='Llamada'? ``:`d-none`}`}>Llamada</span>
                                </div>
                            }>
                                <div className="rounded-bottom w-100 p-3 pb-3" style={{border: "1px solid #dee2e6", borderTop: 0}}>
                                    <input
                                        id="contact-value"
                                        type="tel"
                                        className="form-control"
                                        placeholder="Teléfono a 10 dígitos"
                                        onKeyUp={(e) => handleKeyPress(e)}
                                        pattern="[0-9]{10}"
                                        required={selected === "Llamada"}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="Email" title={
                                <div className="d-flex align-items-center justify-content-center" onClick={() => setSelected("Email")}>
                                    <img src={Mail} className="mr-1" style={{width: 20}} alt="correo chevrolet rivero linda vista"/>
                                    <span className={`text-primary ml-1 ${selected==='Email'? ``:`d-none`}`}>Correo</span>
                                </div>
                            }>
                                <div className="rounded-bottom w-100 p-3 pb-3" style={{border: "1px solid #dee2e6", borderTop: 0}}>
                                    <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    aria-describedby="emailHelp"
                                    placeholder="ejemplo@gruporivero.com"
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    required={selected === "Email"}
                                    />
                                </div>
                            </Tab>
                        </Tabs>

                        <input type="text" className="form-control"
                            name="00Nf400000UBiZj"
                            id="00Nf400000UBiZj"
                            onChange={e => setSelected(e.target.value)}
                            value={selected}  hidden/> 

                        <input type="text" className="form-control"
                            name="phone"
                            id="phone"
                            onChange={e => setWhatsApp(e.target.value)}
                            value={whatsapp} hidden/> 
                    </div>

                    <div className="form-group pb-3">
                        <label htmlFor="description">Comentario</label>
                        <textarea
                            placeholder="Agrega un comentario"
                            name="description"
                            id="description"
                            className="form-control"
                            rows="2"
                        ></textarea>
                    </div>
                    <div className="form-group pb-3">
                        <small className="form-text text-muted">
                            * Nunca compartiremos tus datos con nadie más.
                        </small>
                    </div>
                    <div className="form-group mb-3">
                        <center>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Le57eEaAAAAAFaBiKOIhLvbDBAXFqoJacXuJ4P3"
                                onChange={handleVerify}
                            />
                        </center>
                    </div>
                    <div className="center text-center pb-3">
                        <input type="submit"
                            id="btn-contactarserv"
                            className="boton boton-primario"
                            style={{width:'80%'}}
                            value={btnTitulo}
                            onChange={verifyToken}
                        />
                    </div>

                    <br/>

                </form>
        </>
    );
}